import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

const url = "https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod";
const msalInstance = new PublicClientApplication(msalConfig);

async function getAccessToken() {
  try {
    const account = await msalInstance.getAllAccounts()[0];
    if (!account) {
      throw new Error("No account found. Please log in first.");
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: ["api://abdc2e3d-b4a5-4e9a-9071-625593fc1e9d/access_as_user"], // Your API scopes
      account,
    });
    return response.accessToken;
  } catch (error) {
      console.error("Token acquisition failed:", error);
      throw error;
  }
}

const fetchProjects = async () => {
  try {
    const apiurl = `${url}/getAllProject`;
    await msalInstance.initialize();
    const token = await getAccessToken();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  headers: myHeaders,
};
    const response = await fetch(apiurl,requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

const addProject = async (newItem) => {
  try {
    const apiUrl = `${url}/projects`;
    await msalInstance.initialize();
    const token = await getAccessToken();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    console.log("add project called");
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(newItem),
    });
    if (!response.ok) {
      throw new Error("Failed to create item");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating item:", error);
    throw error;
  }
};

const fetchProject = async (id) => {
  const apiurl = `https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getProjectById?id=${id}`;
  await msalInstance.initialize();
  const token = await getAccessToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  const requestOptions = {
  headers: myHeaders,
  };
  const response = await fetch(apiurl,requestOptions);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
};

const updateProject = async (updatedItem) => {
  try {
    const apiUrl =
      `https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getProjectById?id=`;

    const response = await fetch(`${apiUrl}${updatedItem.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedItem),
    });
    console.log(updatedItem);
    console.log(`${apiUrl}${updatedItem.id}`);
    if (!response.ok) {
      throw new Error("Failed to update item");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating item:", error);
    throw error;
  }
};

const deleteProject = async (id) => {
  try {
    const apiUrl = "";
    const response = await fetch(`${apiUrl}/${id}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error("Failed to delete item");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting item:", error);
    throw error;
  }
};

const getProjectBySearch = async (search) => {
  try {
    const apiUrl = "";
    await msalInstance.initialize();
    const token = await getAccessToken();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const requestOptions = {
      headers: myHeaders,
      };
    const response = await fetch(`${url}/searchResults?searchTerm=${search}`,requestOptions, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Failed to delete item");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting item:", error);
    throw error;
  }
};
export {
  addProject, deleteProject, fetchProject, fetchProjects, getProjectBySearch, updateProject
};

