import React, { useEffect ,useState} from "react";
import { Button, Form, Input, Select, Upload, message, Row, Col,DatePicker } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import MarkdownEditor from "../../util/MarkdownEditor";
import { Buffer } from 'buffer';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import { jwtDecode } from "jwt-decode";
import moment from 'moment'; 
const msalInstance = new PublicClientApplication(msalConfig);
async function getAccessToken() {
  try {
    const accounts = await msalInstance.getAllAccounts();
    const account = accounts[0];
    if (!account) {
      throw new Error("No account found. Please log in first.");
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: ["api://abdc2e3d-b4a5-4e9a-9071-625593fc1e9d/access_as_user"],
      account,
    });
    return response.accessToken;
  } catch (error) {
    console.error("Token acquisition failed:", error);
    throw error;
  }
}
function getDetailsFromToken(token) {
  try {
    const decodedToken = jwtDecode(token);
    console.log('Decoded token:', decodedToken); // For debugging
    return decodedToken.name || null;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return null;
  }
}

const { BlobServiceClient } = require("@azure/storage-blob");

const accountName = "yashrrtcatalog";
const containerName = "thumbnail";
const { Option } = Select;

const EditProject = ({ onCancel, project }) => {
  const [form] = Form.useForm();
  const [imageName, setImageName] = React.useState(null);
  const [technologies, setTechnologies] = React.useState([]);
  const [subTechnologies, setSubTechnologies] = React.useState([]);
  const [editorValue, setEditorValue] = React.useState("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [userName, setUserName] = useState(""); 
  
  


  useEffect(() => {
    const fetchTechnologies = async () => {
      try {
        await msalInstance.initialize();
        const token = await getAccessToken();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        const requestOptions = {
          headers: myHeaders,
        };
        
        const response = await fetch(
          "https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/getTechnologies",
          requestOptions
        );
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTechnologies(data.technologies);
        setSubTechnologies(data.subTechnologies);
      } catch (error) {
        console.error('Error fetching technologies:', error);
        message.error('Failed to fetch technologies.');
      }
    };
    
    fetchTechnologies();
   
   

    // Populate form with existing project data
    if (project) {
      form.setFieldsValue({
        projectTitle: project.title,
        projectType: project.project_type,
        description: project.description,
        deployedUrl: project.deployed_url,
        confluenceurl: project.confluence_link,
        technology: project.technology_used,
        subTechnology: project.sub_technologies_used,
        attachment: project.attachment_link,
        demoUrl:project.demo_url,
        repositoryUrl:project.repository_link,
        startDate: project.start_date ? moment(project.start_date) : null, // Add start date
        endDate: project.end_date ? moment(project.end_date) : null,
      });
      setEditorValue(project.markdown_text || "");
    }
  }, [project, form]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        await msalInstance.initialize();
        const token = await getAccessToken();
        const uniqueName = getDetailsFromToken(token);
        if (uniqueName) {
          setUserName(uniqueName);
          console.log('Unique name from token:', uniqueName); // For debugging
        }
      } catch (error) {
        console.error("Error fetching user name:", error);
      }
    };

    fetchUserName();
  }, []);
  const handleEditorChange = (newValue) => {
    setEditorValue(newValue);
  };
  //const UserDetails = userInfo.includes("unique_name");

  async function uploadFileToAzure(file, imageName) {
    try {
      const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2026-01-01T15:26:24Z&st=2024-12-06T07:26:24Z&spr=https&sig=MOxTHD4j32LJAfYkBHRT5oaXhA1Yf5p8w89WCihJfXI%3D";
      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net/?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(containerName);

      const blobName = `${Date.now()}-${imageName}`;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: {
          blobContentType: file.type,
        },
      });

      return blockBlobClient.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  }

  function cropImage(file, targetWidth, targetHeight) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");

          const aspectRatio = image.width / image.height;
          const targetAspectRatio = targetWidth / targetHeight;

          let sourceX = 0,
            sourceY = 0,
            sourceWidth = image.width,
            sourceHeight = image.height;
          if (aspectRatio > targetAspectRatio) {
            sourceWidth = image.height * targetAspectRatio;
            sourceX = (image.width - sourceWidth) / 2;
          } else {
            sourceHeight = image.width / targetAspectRatio;
            sourceY = (image.height - sourceHeight) / 2;
          }

          ctx.drawImage(
            image,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            0,
            0,
            targetWidth,
            targetHeight
          );

          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Canvas is empty"));
              }
            },
            file.type || "image/jpeg"
          );
        };

        image.onerror = () => reject(new Error("Failed to load the image"));
      };

      reader.onerror = () => reject(new Error("Failed to read the file"));
      reader.readAsDataURL(file);
    });
  }

  const handleSubmit = async (values) => {
    if (!project?.id) {
      message.error("No project found to update");
      return;
    }

    setIsSubmitting(true);
    try {
      let imageUrl = project.image_url; // Use existing image by default

      // Only upload new image if one was selected
      if (imageName) {
        imageUrl = await uploadFileToAzure(imageName, "shubham");
      }

      const formattedData = {
        id: project.id,
        title: values.projectTitle,
        project_type: values.projectType,
        description: values.description,
        image_url: imageUrl,
        deployed_url: values.deployedUrl,
        confluence_link: values.confluenceurl,
        markdown_text: editorValue,
        technology_used: values.technology,
        sub_technologies_used: values.subTechnology,
        repository_link:values.repositoryUrl,
        demo_url:values.demoUrl,
        attachment_link: values.attachment,
        updated_by:userName,
        start_date: values.startDate?.format('YYYY-MM-DD'), // Add start date
      end_date: values.endDate?.format('YYYY-MM-DD'),
      };

      const response = await fetch(
        `https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/updateProject?id=${project.id}`,
        {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${await getAccessToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formattedData),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update project');
      }

      message.success("Project updated successfully!");
      if (onCancel) onCancel();
    } catch (error) {
      console.error("Error updating project:", error);
      message.error("Failed to update project");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileChange = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      try {
        const file = info.file.originFileObj;
        const croppedBlob = await cropImage(file, 300, 200);
        const croppedFile = new File([croppedBlob], file.name, { type: file.type });
        setImageName(croppedFile);
        message.success("Image prepared for upload");
      } catch (error) {
        console.error("Error processing image:", error);
        message.error("Failed to process the image");
      }
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              name="projectTitle"
              label="Project Title"
              rules={[{ required: true, message: "Please input your project title!" }]}
            >
              <Input placeholder="Enter project title" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="projectType"
              label="Project Type"
              rules={[{ required: true, message: "Please select project type!" }]}
            >
              <Select>
                <Option value='Accelerators'>Accelerators</Option>
                <Option value='Client Project'>Client Project</Option>
                <Option value='Solutions For Yash'>Solutions For Yash</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        
        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[{ required: false, message: "Please select a start date!" }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="endDate"
              label="End Date"
              rules={[
                { required: false, message: "Please select an end date!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue('startDate') || value.isAfter(getFieldValue('startDate'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('End date must be after start date!'));
                  },
                }),
              ]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>


        <Row gutter={50}>
          <Col span={10}>
            <Form.Item name="imageUpload" label="Project Thumbnail">
              <div>
                <Upload
                  accept="image/*"
                  onChange={handleFileChange}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Change Image</Button>
                </Upload>
                {project?.image_url && (
                  <img
                    src={project.image_url}
                    alt="Current thumbnail"
                    style={{ marginTop: '10px', maxWidth: '200px' }}
                  />
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>
      
        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              name="technology"
              label="Technology"
              rules={[{ required: true, message: "Please select a technology!" }]}
            >
              <Select placeholder="Select a technology">
                {technologies.map((tech) => (
                  <Option key={tech.id} value={tech.name}>
                    {tech.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="subTechnology" label="Sub Technology/Tags">
              <Select mode="multiple" placeholder="Select sub technologies/tags">
                {subTechnologies.map((subTech) => (
                  <Option key={subTech.id} value={subTech.name}>
                    {subTech.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
         <Col span ={10}>
              <Form.Item name ="attachment" label = "Attachments"
              rules={[{ required: false, message: "Please attach a attachment!" ,type:"url"}]}>
              {/* <Select>
                    <Option value='pdf'>Pdf </Option>
                    <Option value='word file'>Word file</Option>
                    <Option value='Solutions For Yash'>Image</Option>
                    
                  </Select> */}
              <Input placeholder="Enter attachment url"/>
        
        
              </Form.Item>
              
              </Col>
              <Col span ={10}>
              <Form.Item name ="repositoryUrl" label = "Repository URL"
              rules={[{ required: false, message: "Please attach a repository Link!" ,type:"url"}]}>
              {/* <Select>
                    <Option value='pdf'>Pdf </Option>
                    <Option value='word file'>Word file</Option>
                    <Option value='Solutions For Yash'>Image</Option>
                    
                  </Select> */}
              <Input placeholder="Enter Repository url"/>
        
        
              </Form.Item>
              
              </Col>
              <Col span ={10}>
              <Form.Item name ="demoUrl" label = "Demo URL"
              rules={[{ required: false, message: "Please attach a demo Link!" ,type:"url"}]}>
             
              <Input placeholder="Enter demo url"/>
        
        
              </Form.Item>
              
              </Col>

        <Row gutter={50}>
          <Col span={10}>
            <Form.Item
              name="deployedUrl"
              label="Deployed URL"
              rules={[{ required: true, message: "Please input the deployed URL!", type: "url", warningOnly: true }]}
            >
              <Input placeholder="Enter deployed URL" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="confluenceurl"
              label="Confluence URL"
              rules={[{ required: true, message: "Please input the Confluence URL!", type: "url", warningOnly: true }]}
            >
              <Input placeholder="Enter Confluence URL" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <Form.Item
              name="description"
              label="Summary"
              rules={[{ required: true, message: "Please provide a description!" }]}
            >
              <Input.TextArea placeholder="Enter project description" rows={2} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
        
            <Button 
              type="primary"
              htmlType="submit"
              style={{
                width: "20%",
                backgroundColor: isSubmitting ? "#4CAF50" : undefined,
                borderColor: isSubmitting ? "#4ca2af" : undefined
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update Project'}
            </Button>
          
          </div>
        </Form.Item>
      </Form>
      <MarkdownEditor value={editorValue} onChange={handleEditorChange} />
    </>
  );
};

export default EditProject;