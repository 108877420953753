import React, { useEffect, useState } from "react";
// import Whatsapp from 'D:/RRT-Catalog/YASH-RRT-Catalog-Frontend/src/assets/testImage.webp';
import { fetchProjects } from "../../action/ProjectAction";
import { message, Card, Button, Modal, Spin,Space,Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
//import AddProject from './AddProject';
// import EditProject from './EditProject';
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";

import EditProject from "./EditProject";
import { jwtDecode } from "jwt-decode";
import ScrollToTop from "./ScrollToTop";
const msalInstance = new PublicClientApplication(msalConfig);
async function getAccessToken() {
  try {
    const account = await msalInstance.getAllAccounts()[0];
    if (!account) {
      throw new Error("No account found. Please log in first.");
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: ["api://abdc2e3d-b4a5-4e9a-9071-625593fc1e9d/access_as_user"], // Your API scopes
      account,
    });
    return response.accessToken;
  } catch (error) {
      console.error("Token acquisition failed:", error);
      throw error;
  }
}
function getRolesFromToken(token) {
  try {
    
    const decodedToken = jwtDecode(token);
    return decodedToken.roles || ["user.access"]; 
  } catch (error) {
    console.error("Failed to decode token:", error);
    return [];
  }
}

const { Meta } = Card;

const LearningPoc = (props) => {
  const [projects, setProjects] = useState([]);
  const [editprojects, seteditProjects] = useState([]); // State to hold the fetched projects
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null);
   const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
   const [userInfo,setUserInfo] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling fetch projects");
        const data = await fetchProjects(); //To FEtch projects from API
        const projectType = data.filter((project) =>
          project.project_type.toLowerCase().includes("learning pocs")
        );
        setProjects(projectType); // Set the fetched data to state
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects. Please try again.");
        message.error("Failed to fetch projects. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the fetchData function
  }, []);
  useEffect(() => {
       const fetchRoles = async () => {
         try {
             await msalInstance.initialize();
           const token = await getAccessToken();
           const roles = getRolesFromToken(token);
           console.log(roles,"roles");
            setUserInfo(roles);
            //console.log(roles);
         } catch (error) {
           console.error("Error fetching roles:", error);
         }
       };
   
       fetchRoles();
     }, []);
 
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDescription = (id) => {
    console.log("navigating");
    console.log(id);
    navigate(`/catalog/view-catalog`, { state: id });
  };
  const isUserAuthorized = userInfo.includes("admin.access");

  // const handleDelete = (projectId) => {
  //   // Handle delete action (e.g., call delete API)
  //   console.log(`Delete project with ID: ${projectId}`);
  //   // You can also show a confirmation dialog before deleting
  // };
  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;
  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const handleEdit = async (e, id) => {
    e.stopPropagation();
    // Find the project data for the clicked project
    const projectToEdit = projects.find(project => project.id === id);
    setSelectedProject(projectToEdit);
    setIsModalVisible(true);
  };


  const handleDelete = async (e, id) => {
      e.stopPropagation(); // This prevents the card's onClick from firing
      console.log(id);
      alert('you want to delete form ' + id);
      try {
        await msalInstance.initialize();
        const token = await getAccessToken();
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        myHeaders.append("Content-Type", "application/json");
        const response = await fetch(`https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/deleteProject?id=${id}`,{
          method:'DELETE',
          headers: myHeaders,
          // headers:{
          //   'Content-Type':'application/json'
          // }
        });
        if(response.ok){
           alert('deleted');
          console.log("deleted successful");
        }
        
       
        
        const updatedData = await fetchProjects();
        setProjects(updatedData);
      } catch (error) {
        console.error("Error deleting project:", error);
        message.error("Failed to delete project");
      }
    };
  return (
    <div>
      <BreadCrumbs location={props.location} />
      <div className="project-container" style={styles.container}>
        {projects?.map((project) => (
          <div style={{ padding: "0 0 10px 0" }}>
            <Card
              className="card-container"
              key={project.id}
              hoverable
              style={styles.card}
              cover={
                <img
                  alt={project.title}
                  src={project.image_url}
                  style={styles.image}
                  className="card-image"
                />
              }
              onClick={() => handleDescription(project.id)}
            >
              <Meta
                title={project.title}
                description={truncateText(project?.description, 12)}
              />
              {isUserAuthorized && ( 
               <div className="icon-overlay" style={styles.iconOverlay}>
               
                     <Space>
                                        {[
                            <Tooltip title="Edit">
                            <EditOutlined onClick ={(e)=>{handleEdit (e,project.id)}} style={styles.editIcon} key="edit" />
                           </Tooltip>
                           ,
                            <Tooltip title="Delete">
                            <DeleteOutlined onClick={(e)=>{handleDelete(e,project.id) }} style={styles.deleteIcon} key="delete" />
                           </Tooltip>
                           
                            ]}     
                                        </Space>
                
                  </div>
                    )}
            </Card>
           
             
           
          </div>
        ))}
        <Modal
          title="Edit Project"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width="55%"
          style={{ marginTop: "-30px" }}
          styles={{
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "calc(100vh - 200px)",
          }}
        >

<EditProject 
            onCancel={handleCancel} 
            project={selectedProject}
          />
        </Modal>
        <ScrollToTop/>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(270px, 1fr))",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
  },
  card: {
    width: "290px",
    height: "350px",
    border: "1px solid #ccc",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: ".5rem",
    textAlign:"justify",
  },
  image: {
    width: "100%",
    height: "175px",
    objectFit: "cover",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  iconOverlay: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '8px',
    borderRadius: '8px',
    display: 'none',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  },
  editIcon: {
    fontSize: '18px',
    color: '#1890ff',
    cursor: 'pointer',
    padding: '4px',
  },
  deleteIcon: {
    fontSize: '18px',
    color: '#ff4d4f',
    cursor: 'pointer',
    padding: '4px',
  },
};

export default LearningPoc;

// // src/projects.js
// import React from 'react';

// // Sample data for the projects
// const projectData = [
//   {
//     id: 1,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project One',
//     description: 'This is a description for Project One.',
//   },
//   {
//     id: 2,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Two',
//     description: 'This is a description for Project Two.',
//   },
//   {
//     id: 3,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Three',
//     description: 'This is a description for Project Three.',
//   },
// ];

// const projects = () => {
//   return (
//     <div className="project-container" style={styles.container}>
//       {projectData.map((project) => (
//         <div key={project.id} className="project" style={styles.project}>
//           <img src={project.imgSrc} alt={project.projectName} style={styles.image} />
//           <h3>{project.projectName}</h3>
//           <p>{project.description}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//   },
//   project: {
//     border: '1px solid #ccc',
//     borderRadius: '8px',
//     width: '200px',
//     margin: '10px',
//     padding: '10px',
//     textAlign: 'center',
//   },
//   image: {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '4px',
//   },
// };

// export default projects;
