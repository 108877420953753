import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Divider } from 'antd';
import {
  FacebookOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Flex, Tag } from 'antd';
import {
  CircularProgress,
  Container,
  Paper,
  Typography,
  Grid2 as Grid,
  Alert,
  Link,
  Breadcrumbs,
  IconButton,
  Box,
  Modal,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import image1 from '../../assests/img/catalog.jpeg';
import image2 from '../../assests/img/word file image.png';
import image3 from '../../assests/img/pdf-img.webp';
import pdf1 from '../../assests/img/Doc1.pdf';
import pdf2 from '../../assests/img/doc2.pdf';
import ReactMarkdown from "react-markdown";
import BreadCrumbs from "../BreadCrumbs";
import { Col, Row, Spin } from "antd";
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs'; 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import { fetchProject } from "../../action/ProjectAction";
import ProjectSlider from "./ProjrctSlider";

const iconStyle = {
  marginRight: '10px',
  color: '#1976d2',
  verticalAlign: 'middle',
};
const Description = (props) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
   const [catalogId, setCatalogId] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const renderLink = (url, label = "Click Here") => {
    if (!url || url.trim() === '') {
      return <span style={{ color: '#666', fontSize: '.8rem' }}>NA</span>;
    }
    return (
      <Link
        href={url}
       // onClick={handleDemoClick}
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: '.8rem' }}
      >
        {label}
      </Link>
    );
  };

  useEffect(() => {
    setCatalogId(location.state);
    const getProjectData = async () => {
      try {
        const data = await fetchProject(location.state);
        setProject({
          ...data,
          start_date: data.start_date || "",
          end_date: data.end_date || ""
        });
        console.log("project", project);
      } catch (error) {
        console.error("Error fetching project:", error);
        setError("Failed to fetch project details.");
      } finally {
        setLoading(false);
      }
    };
    getProjectData();
  }, [id]);

  // Function to format date
  const formatDate = (dateString) => {
    if (!dateString || dateString.trim() === "") {
      return "Not specified";
    }
    
    const date = dayjs(dateString);
    if (!date.isValid()) {
      return "Not specified";
    }
    
    return date.format('MMM DD, YYYY'); // Changed format to show month name, date, and year
  };
  const onSearch = (value, _e, info) => {
    console.log(info?.source, value);
    // setsearchvalue("");
    navigate(`/search`, { state: value });
  };
  const handleAttachmentClick = (e) => {
    e.preventDefault();
    
    // Check if it's a SharePoint link
    if (project?.attachment_link?.includes('sharepoint.com')) {
      // Calculate dimensions for the new window (70% of screen size)
      const width = Math.floor(window.screen.width * 0.7);
      const height = Math.floor(window.screen.height * 0.7);
      const left = Math.floor((window.screen.width - width) / 2);
      const top = Math.floor((window.screen.height - height) / 2);

      // Open SharePoint in a new window with specific dimensions
      window.open(
        project.attachment_link,
        'SharePointWindow',
        `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,location=no,status=no`
      );
    } else {
      // For non-SharePoint links, show in modal
      setIsAttachmentOpen(false);
    }
  };
const handleDemoClick = (e) => {
    e.preventDefault();
    if (project?.demo_url?.includes('sharepoint.com')) {
      const width = Math.floor(window.screen.width * 0.7);
      const height = Math.floor(window.screen.height * 0.7);
      const left = Math.floor((window.screen.width - width) / 2);
      const top = Math.floor((window.screen.height - height) / 2);

      window.open(
        project.demo_url,
        'SharePointWindow',
        `width=${width},height=${height},left=${left},top=${top},menubar=no,toolbar=no,location=no,status=no`
      );
    } else {
      setIsAttachmentOpen(false);
    }
  };




  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: '10px',
    top: '10px',
    zIndex: 1000,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
  };





  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };

  const cardStyle = {
    background: 'linear-gradient(145deg, #f0f0f0 0%, #ffffff 100%)',
    border: '1px solid #e0e0e0',
    borderRadius: '12px',
    padding: '20px',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    fontFamily: 'Roboto, Arial, sans-serif',
  };
  // const sliderCardStyle = {
  //   ...cardStyle,
  //   marginTop: '20px',
  //   padding: '25px'
  // };
  // const sliderImageStyle = {
  //   width: '100%',
  //   height: '20vh',
  //   borderRadius: '8px'
  // };
  const dateContainerStyle = {
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    padding: '12px 16px',
    marginTop: '15px',
    marginBottom: '15px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.04)',
    border: '1px solid #e9ecef'
  };

  const dateLabelStyle = {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '600',
    color: '#6CC2E4',
    fontSize: '14px',
    marginRight: '8px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  };

  const dateValueStyle = {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    color:'#6CC2E4',
    fontWeight: '500',
    letterSpacing: '0.3px'
  };

  const dateFlexContainer = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexWrap: 'wrap'
  };

  const dateItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 12px',
    backgroundColor: 'white',
    borderRadius: '6px',
    border: '1px solid #e0e0e0'
  };

  // const dateStyle = {
  //   marginBottom: '10px',
  //   padding: '8px 0',
  //   borderBottom: '1px solid #eee'
  // };

  const iconStyle = {
    marginRight: '10px',
    color: '#1976d2',
    verticalAlign: 'middle',
  };

  const content = <div style={contentStyle} />;
  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
     
      </div>
    );
  }

  if (error) {
    return (
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <>
      <BreadCrumbs location={props.location} />
      <div style={{ fontFamily: "Roboto", marginLeft:'55px'}}>
        <Row>
          <Col span={14}>
            <Box sx={{ padding: "8px" }}>
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" color="black" sx={{marginTop:"10px",  marginBottom:"20px"}}>
                    {project.title}
                  </Typography>
                  {/* <div style={dateContainerStyle}> */}
                    {/* Updated Last Date */}
                    <div style={{ marginBottom: '10px'}}>
                      <span style={{fontWeight:'normal' }}>Updated Last on : </span>
                      <span style={dateValueStyle }>{formatDate(project.updated_last)}</span>
                    </div>
                    <div style={{ marginBottom: '10px'}}>
                      <span style={{fontWeight:'normal'}}>Updated by : </span>
                      <span style ={{color:'#6CC2E4'}}>{project.updated_by}</span>
                    </div>

                    {/* <Flex gap="4px 0" wrap style={{ marginBottom: '15px',marginTop:"15px"}}>
    <a href="https://x.com/YASH_Tech" target="_blank" rel="noopener noreferrer">
    <Tag icon={<TwitterOutlined />} color="#55acee">
      Twitter
    </Tag>
    </a>
    <a href="https://www.youtube.com/@yashtechnologies" target="_blank" rel="noopener noreferrer">
    <Tag icon={<YoutubeOutlined />} color="#cd201f">
      Youtube
    </Tag>
    </a>
    <a href="https://www.facebook.com/YASHCorporate/" target="_blank" rel="noopener noreferrer">
    <Tag icon={<FacebookOutlined />} color="#3b5999">
      Facebook
    </Tag>
    </a>
   <a href="https://www.linkedin.com/company/yash-technologies/" target="_blank" rel="noopener noreferrer">
        <Tag icon={<LinkedinOutlined />} color="#0077b5">LinkedIn</Tag>
      </a>
  </Flex> */}
                </Grid>
              </Grid>

              <Typography variant="body1" color="textSecondary" paragraph sx={{
  // fontFamily: "BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  fontFamily:"Roboto",
  fontWeight:'500', 
  fontSize:'14px', 
  marginTop:"10px", 
  marginBottom: "10px", 
  color:'black',
  textAlign:'justify',
  fontSize:'16px'
}}>
  {project.description}
</Typography>

              
              {/* <Typography variant="h4" color="primary">
                Project Details
              </Typography> */}
              {/* <div style={{ marginBottom: '15px' }}>
                <Typography variant="body1" style={{ marginBottom: '10px' }}>
                  <strong>Start Date: </strong>
                  {formatDate(project.start_date)}
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '20px' }}>
                  <strong>End Date: </strong>
                  {project && formatDate(project.end_date)}
                </Typography>
              </div> */}
              <div style={{marginTop: '10px', textAlign:'justify', fontFamily:'Roboto',fontSize:'16px'}}>
                <ReactMarkdown>{project.markdown_text}</ReactMarkdown>
              </div>
            </Box>
          </Col>
          <Col span={10}>
            <Box sx={{ padding: "8px", width: '80%', marginLeft: "10%"}}>
              <div style={{...cardStyle, marginBottom: '20px'}}>
                <Typography variant="h6" color="primary" style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
                  <CodeIcon style={iconStyle} /> Technology Details
                </Typography>
                {/* <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
                  <strong>Primary Technology:</strong>{" "}
                  {project.technology_used}
                </Typography>
                <Typography style={{fontSize:'.8rem'}}>
                  <strong>Sub Technologies:</strong>{" "}
                  {project.sub_technologies_used.join(", ")}
                </Typography> */}

<div style={{ margin: "10px" }}>
      <Typography style={{ marginBottom: "8px", fontSize: ".9rem" }}>
        <strong>Primary Technology:</strong>{" "}
      </Typography>
      <Tag
        color="default"
        style={{
          cursor: "pointer",
          marginRight: "10px",
          marginBottom: "10px",
          borderRadius: "12px",
          transition: "0.3s",
          backgroundColor:"rgb(238 234 234 / 65%)"
        }}
        onClick={() => onSearch(project.technology_used)}
onMouseOver={(e) => (e.target.style.backgroundColor = "#29b6f6")} // Sky blue on hover
onMouseOut={(e) => (e.target.style.backgroundColor = "rgb(238 234 234 / 65%)")} // Reset on hover out
      >
        {project.technology_used}
      </Tag>
 
      <Typography style={{ fontSize: ".9rem", marginBottom: "8px",marginTop:"8px" }}>
        <strong>Sub Technologies:</strong>{" "}
      </Typography>
      {project.sub_technologies_used.map((tech, index) => (
        <Tag
          key={index}
          color="default"
          style={{
            cursor: "pointer",
            marginRight: "10px",
            marginBottom: "10px",
            borderRadius: "12px",
            transition: "0.3s",
            backgroundColor:"rgb(238 234 234 / 65%)"
          }}
          onClick={() => onSearch(tech)}
onMouseOver={(e) => (e.target.style.backgroundColor = "#29b6f6")}
onMouseOut={(e) => (e.target.style.backgroundColor = "rgb(238 234 234 / 65%)")}
        >
          {tech}
        </Tag>
      ))}
    </div>
              </div>
              {project.demo_url && (
  <div style={{ ...cardStyle, marginBottom: '20px' }}>
    <Typography variant="h6" color="primary" style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
      <SmartDisplayIcon style={iconStyle} /> Demo Video
    </Typography>
    <Typography style={{ fontSize: '.8rem' }}>
      <Link
        href={project?.demo_url}
        onClick={handleDemoClick}
         className="inline-flex items-center gap-2 px-4 py-2 text-sm font-bold text-white bg-blue-500 rounded-md hover:scale-110 active:scale-90 transition-transform duration-300"
        style={{
          textDecoration: 'none',
          fontWeight: 'bold',
          fontSize: '.8rem',
          backgroundColor: '#1976d2',
          color: 'white',
          padding: '8px 16px',
          borderRadius: '4px',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <SmartDisplayIcon style={{ width: '16px', height: '16px' }} />
        Watch Video
      </Link>
    </Typography>
  </div>
)}

              <div style={{...cardStyle, backgroundColor: '#f9f9f9'}}>
    <Typography variant="h6" color="primary" style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
    <InfoIcon  style={iconStyle} /> Project Details
    </Typography>
    <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
    <strong>Start Date:</strong>{" "}
    {formatDate(project.start_date)}
    </Typography>
   <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
    <strong>End Date:</strong>{" "}
    {formatDate(project.end_date)}
  </Typography>
  <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
        <strong>Deployed URL:</strong>{" "}
        {renderLink(project.deployed_url)}
      </Typography>

  <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
        <strong>Repository  Link:</strong>{" "}
        {renderLink(project.repository_link)}
      </Typography>
                <Typography style={{marginBottom: '10px', fontSize:'.8rem'}}>
        <strong>Confluence Link:</strong>{" "}
        {renderLink(project.confluence_link)}
      </Typography>
  {/* <Typography style={{marginBottom: '10px'}}>
        <strong>Demo URL:</strong>{" "}
        {renderLink(project.demo_url)}
      </Typography> */}
  {/* <Typography style ={{marginBottom:'10px', fontSize:'.8rem'}}>
                  <strong>Demo URL:</strong>{" "}
                  {project.demo_url==null?
                  <span style={{ color: '#666', fontSize: '.8rem' }}>NA</span>
:<Link
href={project?.demo_url}
onClick={handleDemoClick}
color="primary"
style={{textDecoration: 'none', fontWeight: 'bold', fontSize:'.8rem'}}
>
Click Here
</Link>}
                </Typography> */}

      <Typography style ={{fontSize:'.8rem'}}>
                  <strong>Attachments:</strong>{" "}
                  <Link
                    href={project?.attachment_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    style={{textDecoration: 'none', fontWeight: 'bold'}}
                  >
                    Click Here
                  </Link>
                </Typography>
</div>
              {/* <ProjectSlider iconStyle={iconStyle} /> */}
              {/* <div style={sliderCardStyle}>
        <Typography variant="h6" color="primary" style={{marginBottom: '15px', display: 'flex', alignItems: 'center'}}>
          <ImageIcon style={iconStyle} /> Project Preview
        </Typography>
        {sampleImages.length > 0 && (
          sampleImages.length === 1 ? (
            <div onClick={() => handleImageClick(0)} style={{ cursor: 'pointer' }}>
              <img 
                src={sampleImages[0].url} 
                alt={sampleImages[0].caption}
                style={sliderImageStyle}
              />
              <Typography 
                align="center" 
                style={{marginTop: '10px', color: '#666'}}
              >
                {sampleImages[0].caption}
              </Typography>
            </div>
          ) : (
            <Slider {...sliderSettings}>
              {sampleImages.map((image, index) => (
                <div key={index} onClick={() => handleImageClick(index)} style={{ cursor: 'pointer' }}>
                  <img 
                    src={image.url} 
                    alt={image.caption}
                    style={sliderImageStyle}
                  />
                  <Typography 
                    align="center" 
                    style={{marginTop: '10px', color: '#666'}}
                  >
                    {image.caption}
                  </Typography>
                </div>
              ))}
            </Slider>
          )
        )}
      </div>
      <Modal
        open={activeModal !== null}
        onClose={handleCloseModal}
        aria-labelledby="content-preview-modal"
      >
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              zIndex: 1000,
              color: '#fff',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Slider {...modalSliderSettings}>
            {getModalContent(activeModal).map((content, index) => (
              <div key={index}>
                {renderModalContent(content)}
                <Typography 
                  align="center" 
                  style={{
                    marginTop: '10px', 
                    color: '#666', 
                    fontSize: '16px', 
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px'
                  }}
                >
                  {content.type === 'pdf' && <PictureAsPdfIcon color="error" />}
                  {content.caption}
                </Typography>
              </div>
            ))}
          </Slider>
        </Box>
      </Modal> */}

            </Box>
          </Col>
        </Row>
        <Modal
          open={isAttachmentOpen}
          onClose={() => setIsAttachmentOpen(false)}
          aria-labelledby="attachment-modal"
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <Box sx={modalStyle}>
            <IconButton
              onClick={() => setIsAttachmentOpen(false)}
              sx={closeButtonStyle}
            >
              <CloseIcon />
            </IconButton>
            <iframe
              src={project?.attachment_link}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                borderRadius: '8px',
              }}
              title="Attachment View"
            />
          </Box>
        </Modal>


      </div>
    </>
  );
};

export default Description;