import React, { useEffect, useState } from "react";
import { fetchProjects } from "../../action/ProjectAction";
import { message, Card, Modal, Spin, Space, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import EditProject from "./EditProject";
import { jwtDecode } from "jwt-decode";
const msalInstance = new PublicClientApplication(msalConfig);
async function getAccessToken() {
  try {
    const account = await msalInstance.getAllAccounts()[0];
    if (!account) {
      throw new Error("No account found. Please log in first.");
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: ["api://abdc2e3d-b4a5-4e9a-9071-625593fc1e9d/access_as_user"], // Your API scopes
      account,
    });
    return response.accessToken;
  } catch (error) {
      console.error("Token acquisition failed:", error);
      throw error;
  }
}
function getRolesFromToken(token) {
  try {
    
    const decodedToken = jwtDecode(token);
    return decodedToken.roles || ["user.access"]; 
  } catch (error) {
    console.error("Failed to decode token:", error);
    return [];
  }
}


const { Meta } = Card;

const ProjectCards = () => {
  const [projects, setProjects] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [userInfo,setUserInfo] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling fetch projects");
        const data = await fetchProjects();
        setProjects(data);
      } catch (err) {
        console.error("Error fetching projects:", err);
        setError("Failed to fetch projects. Please try again.");
        message.error("Failed to fetch projects. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
      const fetchRoles = async () => {
        try {
            await msalInstance.initialize();
          const token = await getAccessToken();
          const roles = getRolesFromToken(token);
          console.log(roles,"roles");
           setUserInfo(roles);
        } catch (error) {
          console.error("Error fetching roles:", error);
        }
      };
  
      fetchRoles();
    }, []);

  const handleDescription = (id) => {
    navigate(`/catalog/view-catalog`, { state: id });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };
  const isUserAuthorized = userInfo.includes("admin.access");

  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };

  const content = <div style={contentStyle} />;

  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleEdit = async (e, id) => {
    e.stopPropagation();
    // Find the project data for the clicked project
    const projectToEdit = projects.find(project => project.id === id);
    setSelectedProject(projectToEdit);
    setIsModalVisible(true);
  };
  const handleDelete = async (e, id) => {
    e.stopPropagation(); // This prevents the card's onClick from firing
    console.log(id);
    alert('you want to delete form ' + id);
    try {
      await msalInstance.initialize();
      const token = await getAccessToken();
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
  
      myHeaders.append("Content-Type", "application/json");
      const response = await fetch(`https://0swpo4h32k.execute-api.ap-south-1.amazonaws.com/prod/deleteProject?id=${id}`,{
        method:'DELETE',
        headers: myHeaders,
        // headers:{
        //   'Content-Type':'application/json'
        // }
      });
      if(response.ok){
         alert('deleted');
        console.log("deleted successful");
      }
      
     
      
      const updatedData = await fetchProjects();
      setProjects(updatedData);
    } catch (error) {
      console.error("Error deleting project:", error);
      message.error("Failed to delete project");
    }
  }
  // const getFilteredProjects = () => {
  //   if (!dateRange || !dateRange[0] || !dateRange[1]) {
  //     return projects;
  //   }

  //   const [startDate, endDate] = dateRange;
  //   return projects.filter(project => {
  //     const projectStartDate = new Date(project.start_date);
  //     const projectEndDate = new Date(project.end_date);
  //     const filterStart = new Date(startDate);
  //     const filterEnd = new Date(endDate);

  //     // Check if the project dates overlap with the filter range
  //     return projectStartDate <= filterEnd && projectEndDate >= filterStart;
  //   });
  // };

  

  return (
    <div>
      <div className="project-container" style={styles.container}>
        {projects?.map((project) => (
          <div style={{ padding: "0 0 10px 0" }} key={project.id}>

            
            <Card
              className="card-container"
              hoverable
              style={styles.card}
              cover={
                <div style={styles.imageContainer}>
                  <img
                    alt={project.title}
                    src={project.image_url}
                    style={styles.image}
                    className="card-image"
                  />
                  
                </div>
              }
              onClick={() => handleDescription(project.id)}
            >
              
              <Meta
                title={project.title}
                description={truncateText(project?.description, 15)}
              />
               {isUserAuthorized && ( 
              <div className="icon-overlay" style={styles.iconOverlay}>
                    <Space>
                    {[
        <Tooltip title="Edit">
        <EditOutlined onClick ={(e)=>{handleEdit (e,project.id)}} style={styles.editIcon} key="edit" />
       </Tooltip>
       ,
        <Tooltip title="Delete">
        <DeleteOutlined onClick={(e)=>{handleDelete(e,project.id) }} style={styles.deleteIcon} key="delete" />
       </Tooltip>
       
        ]}     
                    </Space>
                    
                  </div>
                 )}
            </Card>
          </div>
        ))}
        <Modal
          title="Edit Project"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width="55%"
          style={{ marginTop: "-30px" }}
          styles={{
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "calc(100vh - 200px)",
          }}
        >

<EditProject 
            onCancel={handleCancel} 
            project={selectedProject}
          />
        </Modal>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(270px, 1fr))",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
  },
  card: {
    width: "290px",
    height: "350px",
    border: "1px solid #ccc",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: ".5rem",
    textAlign: "justify",
  },
  imageContainer: {
    position: 'relative',
    height: '175px',
  },
  image: {
    width: "100%",
    height: "175px",
    objectFit: "cover",
    // borderTopLeftRadius: "20px",
    // borderTopRightRadius: "20px",
  },
  iconOverlay: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '8px',
    borderRadius: '8px',
    display: 'none',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
  },
  editIcon: {
    fontSize: '18px',
    color: '#1890ff',
    cursor: 'pointer',
    padding: '4px',
  },
  deleteIcon: {
    fontSize: '18px',
    color: '#ff4d4f',
    cursor: 'pointer',
    padding: '4px',
  }

};

export default ProjectCards;