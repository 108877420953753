import React, { useEffect, useState } from "react";
import { Button, Layout, message, Modal,DatePicker,Space } from "antd";
import { FilterOutlined } from '@ant-design/icons';

import Card from "./ProjectCards";
import AddProject from "./AddProject";
import { fetchProjects } from "../../action/ProjectAction";
import BreadCrumbs from "../BreadCrumbs";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../authConfig";
import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';
import ScrollToTop from "./ScrollToTop";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const msalInstance = new PublicClientApplication(msalConfig);

async function getAccessToken() {
  try {
    const account = await msalInstance.getAllAccounts()[0];
    if (!account) {
      throw new Error("No account found. Please log in first.");
    }
    const response = await msalInstance.acquireTokenSilent({
      scopes: ["api://abdc2e3d-b4a5-4e9a-9071-625593fc1e9d/access_as_user"], // Your API scopes
      account,
    });
    return response.accessToken;
  } catch (error) {
      console.error("Token acquisition failed:", error);
      throw error;
  }
}
function getRolesFromToken(token) {
  try {
    
    const decodedToken = jwtDecode(token);
    
    return decodedToken.roles || ['']; 
   
    
  } catch (error) {
    console.error("Failed to decode token:", error);
    return [];
  }
}

const ContentHome = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
    const [userInfo,setUserInfo] = useState("");
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
          await msalInstance.initialize();
        const token = await getAccessToken();
        const roles = getRolesFromToken(token);
        console.log(roles,"roles");
         setUserInfo(roles);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const handleCreateClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const handleFilterClick = () => {
    setIsFilterModalVisible(true);
  };

  const handleFilterCancel = () => {
    setIsFilterModalVisible(false);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleApplyFilter = () => {
    if (dateRange) {
      const [startDate, endDate] = dateRange;
      // Pass these dates to your Card component
      setIsFilterModalVisible(false);
    }
  };

  const handleClearFilter = () => {
    setDateRange(null);
    setIsFilterModalVisible(false);
  };
    const isUserAuthorized = userInfo.includes("admin.access");
//  const isAdmin = roles.includes("admin.access");

  return (
    <Content>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // margin: "10px 10px 5px 0",
        }}
      >
        <BreadCrumbs location={props.location} />
        <Space>
          {/* <Button 
            icon={<FilterOutlined />} 
            onClick={handleFilterClick}
            style={{ marginRight: '10px' }}
          >
            Filter by Date
          </Button> */}
          {isUserAuthorized && (
            <Button type="primary" onClick={handleCreateClick}>
              Add New Project
            </Button>
             )}
        </Space>
          
      </div>
      <div
        style={{
          // background: 'white',
          minHeight: 280,
        }}
      >
        <Card />
      </div>
      <Modal
        title="Add New Project"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="55%"
        style={{ marginTop: "-30px" }}
        styles={{
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <AddProject onCancel={handleCancel} />
      </Modal>
      <Modal
        title="Filter Projects by Date"
        open={isFilterModalVisible}
        onCancel={handleFilterCancel}
        footer={[
          <Button key="clear" onClick={handleClearFilter}>
            Clear Filter
          </Button>,
          <Button key="cancel" onClick={handleFilterCancel}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleApplyFilter}>
            Apply
          </Button>,
        ]}
      >
        <div style={{ padding: '20px 0' }}>
          <RangePicker
            style={{ width: '100%' }}
            value={dateRange}
            onChange={handleDateRangeChange}
            format="YYYY-MM-DD"
          />
        </div>
      </Modal>
      <ScrollToTop/>
    </Content>
  );
};

export default ContentHome;
