export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/catalog",
  CATALOG: "/catalog",
  VIEW_CATALOG: "/catalog/view-catalog",
  ACCELERATOR: "/accelerator",
  CLIENT_PROJECTS: "/client-projects",
  SOLUTIONS_FOR_YASH: "/solutions-for-yash",
  SEARCH: "/search",
  Learning_POCs:"/learning-poc",
};
export const brand = {
  NAME: "RRT Catalog",
};
