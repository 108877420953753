import React, { useEffect, useState } from "react";
// import Whatsapp from 'D:/RRT-Catalog/YASH-RRT-Catalog-Frontend/src/assets/testImage.webp';
import { fetchProjects, getProjectBySearch } from "../../action/ProjectAction";
import { message, Card, Button, Modal, Spin, Result } from "antd";
//import AddProject from './AddProject';
// import EditProject from './EditProject';
import { useNavigate } from "react-router-dom";
import { FileExcelOutlined, LoadingOutlined } from "@ant-design/icons";
import BreadCrumbs from "../BreadCrumbs";

const { Meta } = Card;

const SearchResult = (props) => {
  const [projects, setProjects] = useState([]);
  const [editprojects, seteditProjects] = useState([]); // State to hold the fetched projects
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const fetchData = async (search) => {
    try {
      console.log("calling fetch projects");
      const data = await getProjectBySearch(search); //To FEtch projects from API
      console.log("data:", data.data);
      setProjects(data.data); // Set the fetched data to state
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError("Failed to fetch projects. Please try again.");
      message.error("Failed to fetch projects. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(props.location.state);
  }, [props.location.state]);
  const handleEdit = (project) => {
    // Handle edit action (e.g., open edit modal or navigate to edit page)
    console.log(`Edit project with ID: ${project}`);
    seteditProjects(project);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleDescription = (id) => {
    console.log("navigating");
    console.log(id);
    navigate(`/catalog/view-catalog`, { state: id });
  };

  const handleDelete = (projectId) => {
    // Handle delete action (e.g., call delete API)
    console.log(`Delete project with ID: ${projectId}`);
    // You can also show a confirmation dialog before deleting
  };
  const contentStyle = {
    padding: 130,
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: 4,
  };
  const content = <div style={contentStyle} />;
  if (loading) {
    return (
      <div style={{ alignItems: "center", display: "grid" }}>
        <Spin tip="Loading..." size="large" style={{ color: "#29b6f6" }}>
          {content}
        </Spin>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  return (
    <div>
      {/* <BreadCrumbs location={props.location} /> */}
      {projects && (
        <>
          <div style={{ margin: "5px 0px 5px 20px", fontSize: "17px" }}>
            Showing result(s) for: <b>{props.location.state}</b>
          </div>

          <div className="project-container" style={styles.container}>
            {projects?.map((project) => (
              // <div>
              <div style={{ padding: "0 0 10px 0" }}>
                <Card
                  className="card-container"
                  key={project.id}
                  hoverable
                  style={styles.card}
                  cover={
                    <img
                      alt={project.title}
                      src={project.image_url}
                      style={styles.image}
                      className="card-image"
                    />
                  }
                  onClick={() => handleDescription(project.id)}
                >
                  <Meta
                    title={project.title}
                    description={truncateText(project?.description, 12)}
                  />
                </Card>
                <div style={styles.buttonContainer}>
                  {/* <Button type="primary" variant="default" color='primary' onClick={() => handleEdit(project)} style={styles.button}>
              Edit
            </Button>
            <Button type="primary" variant="default" color='danger' onClick={() => handleDelete(project.id)} style={styles.button}>
              Delete
            </Button> */}
                </div>
              </div>
            ))}
            <Modal
              title="Edit Project"
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              width="55%"
              style={{ marginTop: "-30px" }}
              styles={{
                overflowY: "scroll",
                overflowX: "hidden",
                maxHeight: "calc(100vh - 200px)",
              }}
            >
              {/* <EditProject onproject={editprojects} onCancel={handleCancel} /> */}
            </Modal>
          </div>
        </>
      )}
      {projects.length == 0 && (
        <div>
          <Result status="warning" title="No Data Found" />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(270px, 1fr))",
    gap: "20px",
    padding: "20px",
    justifyContent: "center",
  },
  card: {
    width: "290px",
    height: "350px",
    border: "1px solid #ccc",
    boxShadow: "0 .3rem .8rem rgba(0,0,0,.15)",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "20px",
  },
  image: {
    width: "100%",
    height: "175px",
    objectFit: "cover",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
};

export default SearchResult;

// // src/projects.js
// import React from 'react';

// // Sample data for the projects
// const projectData = [
//   {
//     id: 1,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project One',
//     description: 'This is a description for Project One.',
//   },
//   {
//     id: 2,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Two',
//     description: 'This is a description for Project Two.',
//   },
//   {
//     id: 3,
//     imgSrc: 'https://via.placeholder.com/150',
//     projectName: 'Project Three',
//     description: 'This is a description for Project Three.',
//   },
// ];

// const projects = () => {
//   return (
//     <div className="project-container" style={styles.container}>
//       {projectData.map((project) => (
//         <div key={project.id} className="project" style={styles.project}>
//           <img src={project.imgSrc} alt={project.projectName} style={styles.image} />
//           <h3>{project.projectName}</h3>
//           <p>{project.description}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//   },
//   project: {
//     border: '1px solid #ccc',
//     borderRadius: '8px',
//     width: '200px',
//     margin: '10px',
//     padding: '10px',
//     textAlign: 'center',
//   },
//   image: {
//     width: '100%',
//     height: 'auto',
//     borderRadius: '4px',
//   },
// };

// export default projects;
